import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

export default class BasicAccessGetter extends AbstractAccessGetter {
  protected webname: string;

  constructor(webname: string) {
    super();
    this.webname = webname;
  }
  getWebCommand(path: string): string {
    return this.webname;
  }
  getAccess(path: string, commands: string[]): boolean {
    return this.hasCommand(this.webname, commands);
  }
}
