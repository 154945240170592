
import store from "../store";
import { defineComponent, ref, computed } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import "@entrinsik/informer-vue3/dist/style.css";
import { Informer, AssistantChat } from "@entrinsik/informer-vue3";
import Checkbox from "primevue/checkbox";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Card from "primevue/card";
import { set } from "lodash";
import AIChatMock from "@/components/UI/AIChatMock.vue";

export default defineComponent({
  components: {
    Informer,
    AssistantChat,
    Checkbox,
    TextArea,
    Dialog,
    Button,
    Card,
    AIChatMock,
  },

  watch: {
    $route(to, from) {
      // not implementing for now
      // this would be used to update prompts/system messages based on the route
      //this.updateAssistant(to);
      //this.updatePrompts(to);
    },
    // Watcher for getCurrentCustomer
    getCurrentCustomer(newValue, oldValue) {
      // not implementing for now
      // this would be used to update prompts/system messages based on the active customer
      //this.updatePrompts(this.$route);
    },
  },
  updated() {
    // not implementing for now
    // this would be used to update prompts/system messages based on the route
    //this.updatePrompts(this.$route);
    //this.updateAssistant(this.$route);
  },
  computed: {
    ...mapState(["shipControl"]),
    ...mapGetters({
      subkey: "session/getSubKey",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getModules: "env/getModules",
      getInformerJwt: "ai/getInformerJwt",
      getInformerDefaultAssistant: "ai/getInformerDefaultAssistant",
      getRoverJwt: "session/getRoverJwt",
    }),
    getAISystemMessage(): string {
      return this.aiSystemMessage;
    },
    isAIavailable(): boolean {
      return (
        this.getInformerJwt &&
        this.getInformerDefaultAssistant &&
        this.getModules.length > 0 &&
        (this.getModules.includes("AIChat") ||
          this.getModules.includes("AIMockChat"))
      );
    },
    buttonStyles(): Record<string, string> {
      return {
        color: this.isLocked ? "#0d89ec" : "#EC7834",
      };
    },
  },
  data() {
    return {
      hiddenSidebar: true,
      prompts: [] as string[],
      aiDebug: false,
      aiSystemMessage: "",
      aiAPIRoot: process.env.VUE_APP_AI_BASE_API_URL,
      showSettings: false,
      isLocked: true,
    };
  },
  methods: {
    updateAssistant(route: any) {
      // not implementing for now
      // this would be used to change the assistant based on the route
    },
    updatePrompts(route: any) {
      // not implementing for now
      // this would be used to change prompts or system messages based on the route
    },

    toggleSettings(event: any) {
      this.showSettings = !this.showSettings;
    },

    toggleLock(event: any) {
      this.isLocked = !this.isLocked;
      if (this.isLocked) {
        document.removeEventListener("click", this.closeSidebarOnClickOutside);
      } else {
        document.addEventListener("click", this.closeSidebarOnClickOutside);
      }
    },

    toggleSidebar(event: any) {
      this.hiddenSidebar = !this.hiddenSidebar;
    },

    closeSidebarOnClickOutside(event: any) {
      if (
        event.target.id === "chat-btn" ||
        event.target.innerText === "Start over"
      ) {
        this.hiddenSidebar = false;
      } else if (
        this.$refs.chatSidebar &&
        !(this.$refs.chatSidebar as any).contains(event.target)
      ) {
        this.hiddenSidebar = true;
      }
    },
  },
});
