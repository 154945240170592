import { isEqual, omitBy, isArray, assign } from "lodash";
import Log from "./logs";

export default class Customer {
  cust_id = "";
  name = "";
  address_items = [] as address[];
  sort_city = "";
  sort_state = "";
  sort_zip = "";
  stamp_date = "";
  sort_country = "";
  phone = "";
  bill_to = "";
  ar_bal = "";
  open_order_bal = "";
  contact_id_items = [] as basecontact[];
  change_date_items = [] as changeitem[];
  carrier_items = [] as carrier[];
  quicklist_name_items = [] as quicklist[];
  attachments_items = [] as attachment[];
  ship_seq_items = [] as ShipItem[];
  credit_card_no_items = [] as CreditCard[];
  bank_routing_items = [] as ACH[];
  rebate_type_items = [] as rebate[];
  from_sales_amt_items = [] as fromSales[];
  disc = "";
  terms = "";
  currency_code = "";
  credit_limit = "";
  credit_hold = "";
  code = "";
  partials_ok = "";
  ship_via = "";
  freight_code = "";
  group_code_items = [] as group[];
  notes = "";
  end_user_cntry_items = [] as country[];
  rep_items = [] as RepItem[];
  website = "";
  mrk_opt_out = "";
  date = "";
  resale = "";
  highest_ar_bal = "";
  avg_ar_bal = "";
  ecommerce_level = "";
  log_ids = [] as Log[];
  saleopp_ids: any;
  soquote_ids: any;
  so_ids: any;
  rma_ids: any;
  ar_ids: any;
  ticket_ids: any;
  fso_ids: any;
  inactive_date = "";
  tax_codes_items = [] as TaxCodes[];
  activeTab = 0;
}

export interface address {
  address: string | null;
}
export interface RepItem {
  order_pct: string | null;
  rep: string | null;
  rep_name: string | null;
}
export interface CreditCard {
  credit_card_no: string;
  credit_card_exp: string;
  credit_card_id: string;
}

export interface ACH {
  bank_account: string;
  bank_routing: string;
  account_type: string;
  bank_token: string;
}

export interface country {
  end_user_cntry: string | null;
}
export interface basecontact {
  contact_id: string | null;
  contact_type: string | null;
  contact_portal: string | null;
  contact_name?: string;
  contact_email?: string;
}

export class ShipItem {
  ship_seq = "";
  ship_city = "";
  ship_state = "";
  ship_zip = "";
  ship_name = "";
  ship_country = "";
  ship_attn = "";
  ship_comm = "";
  ship_phone = "";
  ship_fax = "";
  ship_email = "";
  ship_to_items = [] as shipAddress[];
  ship_to_tax_items = [] as TaxItems[];
  ship_acct_no_items = [] as ShipAccts[];

  compareTo(other: ShipItem): boolean {
    const shipOne = omitBy(
      this,
      (value) => !value || (isArray(value) && value.length === 0),
    );
    const shipTwo = omitBy(
      other,
      (value) => !value || (isArray(value) && value.length === 0),
    );

    return isEqual(shipOne, shipTwo);
  }

  initFromShipItem(shipItem: ShipItem): ShipItem {
    return assign(this, shipItem);
  }
}

export interface shipAddress {
  ship_to: string | null;
}
export interface TaxItems {
  ship_to_tax: string | null;
}

export interface ShipAccts {
  ship_acct_no: string | null;
  ship_carrier: string | null;
}
export interface changeitem {
  change_date: string | null;
  change_time: string | null;
  changed_by: string | null;
  approved_by: string | null;
  change_text: string | null;
  change_notes: string | null;
}

export interface carrier {
  carrier: string | null;
  frt_account_items: Array<frtaccount> | null;
}
export interface frtaccount {
  frt_account: string | null;
  third_party: string | null;
  tp_street: string | null;
  tp_city: string | null;
  tp_state: string | null;
  tp_zip: string | null;
  tp_country: string | null;
}
export interface quicklist {
  quicklist_name: string | null;
  quicklist_part_items: Array<quicklistpart>;
}
export interface quicklistpart {
  quicklist_part: string | null;
  quicklist_date: string | null;
  quicklist_qty: string | null;
  quicklist_price: string | null;
  quicklist_disc: string | null;
}

export interface attachment {
  attachments: string | null;
  attachment_desc: string | null;
}

export interface rebate {
  rebate_type: string | null;
  rebate_pct: string | null;
  rebate_freq: string | null;
  rebate_acct: string | null;
}
export interface fromSales {
  from_sales_amt: string | null;
  sales_percent: string | null;
  to_sales_amt: string | null;
  sales_frequency: string | null;
  sales_acct: string | null;
}
export interface group {
  group_code: string | null;
  code: string | null;
  description: string | null;
}

export interface TaxCodes {
  tax_codes?: string;
}
