import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import DashboardState from "@/types/state/dashboard";
import InformerService from "@/services/InformerService";
import JWTService from "@/services/JWTService";
import store from "@/store";

const namespaced = true;
const informerService = new InformerService();

export const state: DashboardState = {
  url: "",
};

export const getters: GetterTree<DashboardState, RootState> = {
  getUrl(state): any {
    return state.url;
  },
};

interface UserFields {
  field_name: string;
  value: string;
}

export const mutations: MutationTree<DashboardState> = {
  SET_URL(state, url) {
    const user = store.getters["session/getUser"];
    const payload: any = {
      attributes: {
        userFieldValues: {
          user_id: user.user_id,
        },
      },
    };

    if (user && user.attributes) {
      user.attributes.map((u: UserFields) => {
        const field_name = u.field_name.toLowerCase().replaceAll(".", "_");
        let value = null;
        if (u.value.includes("|")) {
          value = u.value.split("|");
        } else {
          value = [u.value];
        }

        const field = payload.attributes.userFieldValues[field_name];
        if (field) {
          payload.attributes.userFieldValues[field_name] = [...field, ...value];
        } else {
          payload.attributes.userFieldValues[field_name] = value;
        }
      });
    }

    JWTService.generateToken(payload)
      .then((response) => {
        url = url + "&jwt=" + response;
      })
      .finally(() => {
        state.url = url;
      });
  },
};

export const actions: ActionTree<DashboardState, RootState> = {
  getReportUrl({ commit }, { client }) {
    informerService.getReportUrl(client).then((response: any) => {
      if (response.url) {
        commit("SET_URL", response.url);
      }
    });
  },
};

export const dashboard: Module<DashboardState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
