export default abstract class AbstractAccessGetter {
  abstract getAccess(path: string, commands: string[]): boolean;
  abstract getWebCommand(path: string): string;

  getAccessRights(
    path: string,
    commands_rights: { [key: string]: AccessRights },
  ): AccessRights {
    return commands_rights[this.getWebCommand(path)] || new AccessRights();
  }

  hasCommand(webname: string, commands: Array<string> | undefined) {
    if (
      commands?.find((item) => item.toUpperCase() === webname.toUpperCase())
    ) {
      return true;
    }
    return false;
  }
}

export class AccessRights {
  add = false;
  delete = false;
  change = false;
  view = false;
}
