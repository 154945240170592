
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import UserAvatar from "@/components/UI/UserAvatar.vue";
import AIChatAvatar from "@/components/UI/AIChatAvatar.vue";

export default defineComponent({
  name: "AIChatMock",
  components: {
    InputText,
    Button,
    UserAvatar,
    AIChatAvatar,
  },
  props: {
    prompts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      input: "",
      isSubmmited: false,
      messages: [] as Array<{ message: string; isUser: boolean }>,
    };
  },
  methods: {
    handleClickPrompt(prompt: string) {
      this.messages.push({
        message: prompt,
        isUser: true,
      });
      this.messages.push({
        message: "\u25A0",
        isUser: false,
      });
      this.isSubmmited = true;
      setTimeout(() => {
        this.messages[this.messages.length - 1].message =
          "Hello there! 👋 It looks like you're interested in our AI assistant, Rover AI. This feature is part of our Enterprise plan – would you like to learn more about the benefits and how to get access?";
        this.input = "";
        this.isSubmmited = false;
      }, 500);
    },
    handleScroll(interval = 1500) {
      setTimeout(() => {
        const chatContent = document.getElementById("chatContent");
        if (chatContent) {
          const lastItem = chatContent.lastElementChild;
          if (lastItem) {
            lastItem.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }
      }, interval);
    },
  },
  watch: {
    "prompts.length": {
      handler() {
        this.handleScroll(100);
      },
    },
    "messages.length": {
      handler() {
        this.handleScroll();
      },
      deep: true,
    },
  },
});
