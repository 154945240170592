import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ControlState from "@/types/state/control";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: ControlState = {
  saleOpportunity: {
    type_items: null,
    source_items: null,
    class_items: null,
    priority_items: null,
    status_items: null,
  },
  // posTransType: [],
  // posPayType: [],
  formItems: {
    form_name_items : null,
    entry_procedure_items : null,
    procedure_id_items: null
  },
  binItems: [],
  invPlanGroupItems: [],
  attachments: {
    folder_name_items: null,
  },
  categoryItems: [],
  filterItems: [],
  paymentTypes: [],
  transactionTypes: [],
  codeItems: [],
  MailControls: null,
  ar: {
    ar_type_name_items: null,
  },
  ccFee: null,
  achFee: null,
  waiveConvenienceFeeUsers: [],
  waiveConvenienceFeeByDefault: false,
};

export const getters: GetterTree<ControlState, RootState> = {
  getAR: (state) => {
    return state.ar;
  },
  getSaleOpportunity(state) {
    return state.saleOpportunity;
  },
  isSaleOpportunityAvailable(state) {
    let k: keyof typeof state.saleOpportunity;
    const isEmpty = Object.values(state.saleOpportunity).every(
      (x) => x == null,
    );
    return isEmpty;
  },
  isSaleOpportunityControlEmpty(state) {
    let k: keyof typeof state.saleOpportunity;
    const isEmpty = Object.values(state.saleOpportunity).every(
      (x) => x === null,
    );
    return isEmpty;
  },
  getFormItems: (state) => {
    return state.formItems;
  },
  getBinItems: (state) => {
    return state.binItems;
  },
  getInvPlanGroupItems: (state) => {
    return state.invPlanGroupItems;
  },
  getFolderNames: (state) => {
    return state.attachments.folder_name_items;
  },
  getMailControls(state) {
    return state.MailControls;
  },
  getCategoryItems(state) {
    return state.categoryItems;
  },
  getFilterItems(state) {
    return state.filterItems;
  },
  getPaymentTypes(state) {
    return state.paymentTypes;
  },
  getTransactionTypes(state) {
    return state.transactionTypes;
  },
  getCompanyCodes(state) {
    return state.codeItems;
  },
  getACHFee: (state) => {
    return state.achFee;
  },
  getCCFee: (state) => {
    return state.ccFee;
  },
  getWaiveConvenienceFeeUsers: (state) => {
    return state.waiveConvenienceFeeUsers;
  },
  getWaiveConvenienceFeeByDefault: (state) => {
    return state.waiveConvenienceFeeByDefault;
  },
};

export const mutations: MutationTree<ControlState> = {
  SET_SALEOPPORTUNITY(state, controls) {
    state.saleOpportunity = controls;
  },
  SET_FORM_DATA(state, formData) {
    state.formItems = formData;
  },
  SET_BIN_DATA(state, { binData }) {
    state.binItems = binData;
  },
  SET_INV_DATA(state, { invData }) {
    state.invPlanGroupItems = invData;
  },
  SET_FOLDER_NAMES(state, { folderNameData }) {
    state.attachments.folder_name_items = folderNameData;
  },
  PUSH_MAIL(state, mail) {
    state.MailControls = mail;
  },
  SET_POS_CATS(state, catData) {
    state.categoryItems = catData;
  },
  SET_POS_FILTERS(state, filterData) {
    const mutated = [] as any;
    filterData.forEach((tag: any) => {
      if (tag.parent_category) {
        if (
          !mutated.some((cat: any) => {
            return cat.category === tag.parent_category;
          })
        ) {
          mutated.push({
            category: tag.parent_category,
            tags: [{ label: tag.web_category_title, tag: tag.web_category }],
          });
        } else {
          const index = mutated.findIndex((cat: any) => {
            return cat.category === tag.parent_category;
          });
          if (!mutated[index].tags) {
            mutated[index].tags = [];
          }
          mutated[index].tags.push({
            label: tag.web_category_title,
            tag: tag.web_category,
          });
        }
      } else {
        if (
          mutated.some((cat: any) => {
            return cat.category === tag.web_category;
          })
        ) {
          const index = tag.findIndex((cat: any) => {
            return cat.category === tag.web_category;
          });
          mutated[index].label = tag.web_category_title || tag.web_category;
        } else {
          mutated.push({
            category: tag.web_category,
            label: tag.web_category_title || tag.web_category,
          });
        }
      }
    });
    const noEmptyCats = mutated.filter(
      (cat: any) => cat.tags !== null && cat.tags !== undefined,
    );
    state.filterItems = noEmptyCats;
  },
  SET_PAYMENT_TYPES(state, payments) {
    state.paymentTypes = payments;
  },
  SET_TRANSACTION_TYPES(state, trans) {
    state.transactionTypes = trans;
  },
  SET_COMPANY_CODE_ITEMS(state, items) {
    state.codeItems = items;
  },
  SET_AR(state, codes) {
    state.ar = codes || [];
  },
  SET_CC_FEE(state, ccFee) {
    state.ccFee = ccFee;
  },
  SET_ACH_FEE(state, achFee) {
    state.achFee = achFee;
  },
  SET_WAIVE_CONVENIENCE_FEE_USERS(state, items) {
    const user_id_items = items;
    const userIds = user_id_items
      .filter((item: { cfoverride: string }) => item.cfoverride === "Y")
      .map((item: { user_id: any }) => item.user_id);
    state.waiveConvenienceFeeUsers = userIds;
  },
  SET_WAIVE_CONVENIENCE_FEE_BY_DEFAULT(state, waiveConvenienceFeeByDefault) {
    state.waiveConvenienceFeeByDefault = waiveConvenienceFeeByDefault;
  },
  TOGGLE_WAIVE_CONVENIENCE_FEE_BY_DEFAULT(state) {
    state.waiveConvenienceFeeByDefault = !state.waiveConvenienceFeeByDefault;
  },
};

export const actions: ActionTree<ControlState, RootState> = {
  setFilters({ commit }, filters) {
    commit("SET_POS_FILTERS", filters || []);
  },
  toggleWaiveConvenienceFeeByDefault({ commit }) {
    commit("TOGGLE_WAIVE_CONVENIENCE_FEE_BY_DEFAULT");
  },
  setWaiveConvenienceFeeByDefault({ commit }, waiveConvenienceFeeByDefault) {
    commit(
      "SET_WAIVE_CONVENIENCE_FEE_BY_DEFAULT",
      waiveConvenienceFeeByDefault,
    );
  },
  fetchControl({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.fieldnames == null) {
        payload.fieldnames = "";
      }
      controlService
        .getControl(
          payload.id,
          payload.procedure,
          payload.filename,
          payload.fieldnames,
          payload.companyId,
        )
        .then((response: any) => {
          switch (payload.id) {
            case "SALEOPP":
              if (!response[0]) {
                const saleopp = {
                  type_items: [],
                  source_items: [],
                  status_items: [],
                  priority_items: [],
                };
                commit("SET_SALEOPPORTUNITY", saleopp);
              } else {
                commit("SET_SALEOPPORTUNITY", response[0]);
              }
              break;
            case "BIN":
              commit("SET_BIN_DATA", {
                binData: response[0].valid_bins_items,
              });
              break;
            case "FORM":
              commit("SET_FORM_DATA", response[0]);
              break;
            case "MAIL":
              commit("PUSH_MAIL", response[0]);
              break;
            case "INV":
              commit("SET_INV_DATA", {
                invData: response[0].plan_group_items,
              });
              break;
            case "ATTACHMENT":
              commit("SET_FOLDER_NAMES", {
                folderNameData: response[0]?.folder_name_items || [],
              });
              break;
            case "CAT":
              commit("SET_POS_CATS", response[0]?.category_items || []);
              break;
            case "WEB":
              commit("SET_POS_FILTERS", response[0]?.web_category_items || []);
              break;
            case "PSO":
              commit(
                "SET_TRANSACTION_TYPES",
                response[0]?.transaction_items || [],
              );
              commit("SET_PAYMENT_TYPES", response[0]?.payment_items || []);
              break;
            case "COMPANY":
              commit("SET_COMPANY_CODE_ITEMS", response[0]?.code_items || []);
              break;
            case "CC":
              commit("SET_CC_FEE", response[0]?.conv_fee_pct);
              commit(
                "SET_WAIVE_CONVENIENCE_FEE_USERS",
                response[0]?.user_id_items || [],
              );
              break;
            case "AR" + payload.companyId:
              commit("SET_ACH_FEE", response[0]?.conv_fee_pct);
              break;
            case "AR1":
              if (!response[0]) {
                commit("SET_AR", []);
              } else {
                commit("SET_ACH_FEE", response[0]?.conv_fee_pct);
                commit("SET_AR", response[0]?.ar_type_name_items);
              }

              break;
            default:
              break;
          }
          resolve({ message: "success", data: response[0] });
        });
    });
  },
};

export const control: Module<ControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
