import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "help-text"
}
const _hoisted_2 = { style: {"display":"flex","white-space":"pre-line"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Toast, {
      modelValue: _ctx.notificationItems,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notificationItems) = $event)),
      position: "bottom-right"
    }, null, 8, ["modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogNotifications, (item) => {
      return (_openBlock(), _createBlock(_component_Dialog, {
        breakpoints: { '960px': '75vw', '640px': '90vw' },
        style: { width: '350px' },
        visible: item.dialog,
        modal: "",
        header: _ctx.notificationHeader(item),
        closable: false,
        key: item.id
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_Button, {
            label: "Close",
            onClick: ($event: any) => (_ctx.closeNotification(item)),
            autofocus: ""
          }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          (item.subtitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.subtitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_2, [
            (item.type !== 'info')
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass(_ctx.getClassForNotificationType(item.type)),
                  style: {"font-size":"2rem","margin-right":"1rem"}
                }, null, 2))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(item.message), 1)
          ])
        ]),
        _: 2
      }, 1032, ["visible", "header"]))
    }), 128))
  ]))
}