import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

export default class SalesAccessGetter extends AbstractAccessGetter {
  constructor() {
    super();
  }

  getAccess(path: string, commands: string[]): boolean {
    const pathList = path.split("/");
    let webName;
    if (pathList[2] == "orders") {
      return (
        this.hasCommand("SO.E", commands) || this.hasCommand("PSO.E", commands)
      );
    } else if (pathList[2] == "quotes") {
      webName = "SOQUOTE.E";
    } else if (pathList[2] == "opportunities") webName = "SALEOPP.E";
    else {
      return (
        commands.filter((item) =>
          ["SO.E", "SOQUOTE.E", "SALEOPP.E"].includes(item),
        ).length > 0
      );
    }
    return this.hasCommand(webName, commands);
  }

  getAccessRights(
    path: string,
    commands_rights: { [key: string]: AccessRights },
  ): AccessRights {
    const pathList = path.split("/");
    let webName;
    if (pathList[2] == "orders") {
      return commands_rights["SO.E"] || commands_rights["PSO.E"];
    } else if (pathList[2] == "quotes") {
      webName = "SOQUOTE.E";
    } else if (pathList[2] == "opportunities") webName = "SALEOPP.E";
    else {
      return (
        commands_rights["SO.E"] ||
        commands_rights["SOQUOTE.E"] ||
        commands_rights["SALEOPP.E"]
      );
    }
    return commands_rights[webName] || new AccessRights();
  }

  /*
   * Not implemented, getAccessRights is overridden here.
   */
  getWebCommand(path: string): string {
    return "";
  }
}
