import AbstractAccessGetter, { AccessRights } from "./AbstractAccessGetter";
import AccountingAccessGetter from "./getters/AccountingAccessGetter";
import BasicAccessGetter from "./getters/BasicAccessGetter";
import PosAccessGetter from "./getters/PosAccessGetter";
import SalesAccessGetter from "./getters/SalesAccessGetter";
import ScanAccessGetter from "./getters/ScanAccessGetter";

const accessGetters: { [key: string]: AbstractAccessGetter } = {
  dashboard: new BasicAccessGetter("DASHBOARD"),
  cash: new BasicAccessGetter("CASH.E"),
  invoices: new BasicAccessGetter("AR.E"),
  "deal-sheet": new BasicAccessGetter("DEALSHEET"),
  documentControl: new BasicAccessGetter("DOC"),
  engineering: new BasicAccessGetter("ENG"),
  fieldService: new BasicAccessGetter("FEILD"),
  generalLedger: new BasicAccessGetter("LEDGER"),
  manufacturing: new BasicAccessGetter("MAN"),
  marketingAndSales: new BasicAccessGetter("MARKETING"),
  production: new BasicAccessGetter("WO.E"),
  projects: new BasicAccessGetter("PROJ"),
  workFlows: new BasicAccessGetter("WORKFLOW"),
  purchasingReceiving: new BasicAccessGetter("PURCH"),
  customers: new BasicAccessGetter("CUST.Q"),
  tickets: new BasicAccessGetter("TICKET.E"),
  inventory: new BasicAccessGetter("INVENTORY"),
  receipts: new BasicAccessGetter("RECEIPTS.E"),
  parts: new BasicAccessGetter("PARTS.E"),
  fieldservices: new BasicAccessGetter("FSO.E"),
  accounts: new AccountingAccessGetter(),
  accounting: new AccountingAccessGetter(),
  sales: new SalesAccessGetter(),
  scan: new ScanAccessGetter(),
  pos: new PosAccessGetter(),
};

export default class SessionAccessGetterFactory {
  static getLookupPath(path: string): string {
    return path.split("/")[1];
  }

  static getAccessGetter(path: string) {
    return accessGetters[SessionAccessGetterFactory.getLookupPath(path)];
  }

  static getWebAccessGetter(webname: string): AbstractAccessGetter {
    return new BasicAccessGetter(webname);
  }

  static getAccess(path: string, commands: string[] | undefined): boolean {
    if (!commands || commands.length == 0) {
      return false;
    }
    if (!accessGetters[SessionAccessGetterFactory.getLookupPath(path)]) {
      return true;
    }
    return accessGetters[
      SessionAccessGetterFactory.getLookupPath(path)
    ].getAccess(path, commands);
  }

  static getAccessRights(
    path: string,
    commands_rights: { [key: string]: AccessRights },
    commands: string[] | undefined,
  ): AccessRights {
    if (
      commands_rights == undefined ||
      Object.keys(commands_rights).length == 0
    ) {
      // if not provided, fall back to old checks, presence of the command means all access
      if (SessionAccessGetterFactory.getAccess(path, commands)) {
        return {
          add: true,
          delete: true,
          change: true,
          view: true,
        };
      }

      return new AccessRights();
    }

    return accessGetters[
      SessionAccessGetterFactory.getLookupPath(path)
    ].getAccessRights(path, commands_rights);
  }

  static getWebAccess(
    webName: string,
    commands: string[] | undefined,
  ): boolean {
    if (!commands || commands.length == 0) {
      return false;
    }

    return new BasicAccessGetter(webName).hasCommand(webName, commands);
  }

  static getWebAccessRights(
    webName: string,
    commands_rights: { [key: string]: AccessRights },
  ) {
    return new BasicAccessGetter(webName).getAccessRights(
      webName,
      commands_rights,
    );
  }
}
