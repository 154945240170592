
import { Options, Vue } from "vue-class-component";
import { mapState, mapActions } from "vuex";
import Button from "primevue/button";
import Message from "primevue/message";
import Toast from "primevue/toast";
import Notification from "@/types/notification";
import Dialog from "primevue/dialog";
@Options({
  components: {
    Button,
    Message,
    Toast,
    Dialog,
  },
  computed: {
    ...mapState("notification", ["notifications"]),
    notificationItems() {
      const toastItems = this.notifications.forEach((element: any) => {
        if (!element.dialog) {
          this.$toast.add({
            severity: element.type,
            summary: element.message,
            id: element.id,
            life: 5000,
          });
          this.remove(element);
        }
      });
      return toastItems;
    },
    dialogNotifications() {
      return this.notifications.filter((item: any) => item.dialog);
    },
  },
  methods: {
    ...mapActions({
      remove: "notification/remove",
    }),
    closeNotification(item: any) {
      this.remove(item);
    },
    notificationHeader(item: any) {
      if (item.title) {
        return item.title;
      }
      return item.type === "error" ? "Error" : "Success";
    },
    getClassForNotificationType(type: string) {
      //return `pi pi-exclamation-triangle mr-3" style="font-size: 2rem`;
      return type === "error"
        ? 'pi pi-exclamation-triangle mr-3"'
        : "pi pi-check-circle mr-3";
    },
  },
})
export default class NotificationCard extends Vue {
  public notification!: Notification;
}
