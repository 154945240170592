import { FdictResponse } from "@/types/fdict";

export const FDICT_SO = {
  SO_ID: "0",
  DATE: "1",
  SOLD_TO: "2",
  BOOK_DATE: "3",
  BILL_TO: "4",
  SHIP_ADDRESS: "5",
  CONTACT: "6",
  PHONE: "7",
  PO_NUMBER: "8",
  SHIP_VIA: "9",
  REP: "10",
  ORDER_PCT: "11",
  HOLD: "12",
  HOLD_DATE: "13",
  STATUS: "14",
  TERMS_CODE: "15",
  FOB: "16",
  CUST_CODE: "17",
  TAX_RATE: "18",
  RESALE_NUMBER: "19",
  CLOSE_DATE: "20",
  LAST_SHIP_NO: "21",
  LAST_DATE: "22",
  NOTES: "23",
  STAMP_DATE: "24",
  STAMP_ID: "25",
  LIS: "26",
  LI_PARTS: "27",
  LI_DESCS: "28",
  LI_REVS: "29",
  LI_SCHED_DATES: "30",
  LI_SCHED_QTYS: "31",
  LI_ORDER_QTYS: "32",
  LI_OPEN_QTYS: "33",
  LI_PRICES: "34",
  LI_DISCS: "35",
  LI_DISC_AMTS: "36",
  LI_FG_LOCS: "37",
  LI_NOTES: "38",
  LI_TAXABLES: "39",
  LI_REPS: "40",
  LI_COMMS: "41",
  LI_HOLDS: "42",
  LI_HOLD_DATES: "43",
  LI_SHIP_NOS: "44",
  LI_SHIP_DATES: "45",
  LI_SHIP_QTYS: "46",
  LI_TOTAL_SHIP: "47",
  LI_SALES_ACCTS: "48",
  LAST_RMA_NUMBER: "49",
  LAST_RMA_DATE: "50",
  TYPE: "51",
  FREIGHT_CODE: "52",
  LI_QUOTE_NO: "53",
  COMMITED: "54",
  LI_WOS: "55",
  PRINT_DATE: "56",
  REQUIRED: "57",
  PARTIALS_OK: "58",
  TAX_CODES: "59",
  CG_LOC: "60",
  QUOTE_NO: "61",
  LI_QUOTE_LI: "62",
  CUST_PART: "63",
  PLAN_GROUP: "64",
  EXT: "65",
  CO_CODE: "66",
  SHIP_CITY: "67",
  SHIP_STATE: "68",
  SHIP_ZIP: "69",
  SHIP_GEOCODE: "70",
  PROJECT_ID: "71",
  TASK_ID: "72",
  SHIP_SEQ: "73",
  CHANGE_DATE: "74",
  CHANGE_TIME: "75",
  CHANGED_BY: "76",
  APPROVED_BY: "77",
  CHANGE_TEXT: "78",
  CHANGE_NOTES: "79",
  VENDOR: "101",
  PO_COST: "102",
  PO_ID: "103",
  PO_LI: "104",
  DROP_SHIP: "105",
  SHIP_NAME: "106",
  SHIP_COUNTRY: "107",
  SHIP_ATTN: "108",
  SHIP_COMM: "109",
  SHIP_PHONE: "110",
  PRICE_PER: "111",
  PENDING_SHIP: "112",
  CREDITCARD_NO: "113",
  CREDITCARD_TYPE: "114",
  CARDHOLDER_NAME: "115",
  CREDITCARD_EXP: "116",
  CREDITCARD_CVV: "117",
  CREDITCARD_TRAN: "118",
  CREDITCARD_AMT: "119",
  CREDITCARD_POST: "120",
  CC_CONV_FEE: "121",
  SHIP_EMAIL: "122",
  SHIP_FRT_ACCT: "123",
  CONTACT_EMAIL: "124",
  ORDER_SOURCE: "125",
  FREIGHT_AMOUNT: "126",
  FREIGHT_TYPE: "127",
  FREIGHT_LOCK: "128",
  WRAP_DESC: "129",
  COUPON_ID: "130",
  COUPON_TYPE: "131",
  COUP_DISC_AMT: "132",
  COUP_DISC_PCT: "133",
  COUP_MAX_DISC: "134",
  COUP_MIN_ORD: "135",
  COUP_LI: "136",
  COUP_LI_DISC: "137",
  COUP_SHIP_AMT: "138",
  EDI_ORDER: "139",
  CONTACT_ID: "140",
  CM_ID: "141",
  CM_AMOUNT: "142",
  CC_ADDR: "143",
  CC_CITY: "144",
  CC_STATE: "145",
  CC_ZIP: "146",
  CC_COUNTRY: "147",
  CREDITCARD_ERR: "148",
  ATTACHMENTS: "149",
  ATTACHMENT_DESC: "150",
  CURRENCY_CODE: "151",
  EXCHANGE_RATE: "152",
  SAT_DELIVERY: "153",
  SHIPPER_ACCOUNT: "154",
  ACCT_RELEASE_ID: "155",
  END_USER_CNTRY: "156",
  END_USER: "157",
  END_USER_STMT: "158",
  LI_END_USE: "159",
  EXPORT_LIST: "160",
  EXPORT_LIST_CHK: "161",
  FIRST_SHIP_ONLY: "162",
  FRT_SERV_CODE: "163",
  PKG_TYPE_CODE: "164",
  PKG_WEIGHT: "165",
  PKG_WEIGHT_UOM: "166",
  PKG_LENGTH: "167",
  PKG_WIDTH: "168",
  PKG_HEIGHT: "169",
  PKG_DIM_UOM: "170",
  PKG_VALUE: "171",
  FRT_MARKUP_AMT: "172",
  CREDIT_CARD_ID: "173",
  TAX_CODE_RATE: "174",
  TAX_FREIGHT: "175",
  PAYMENT_PLAN: "176",
  PREPAY: "177",
  TAX_AMOUNT: "178",
  MIVA_LI_NO: "179",
  LOT_NUMBER: "201",
  SIGNATURE: "202",
  SIGNATURE_DATE: "204",
  SIGNATURE_TIME: "205",
  SIGNATURE_BY: "206",
  SIGNATURE_TYPE: "207",
  POS_ORDER_CODE: "210",
};
