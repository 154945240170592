import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import AiState from "@/types/state/ai";
import InformerService from "@/services/InformerService";

const namespaced = true;

const informerService = new InformerService();

export const state: AiState = {
  informerJwt: "",
  informerDefaultAssistant: "",
};

export const getters: GetterTree<AiState, RootState> = {
  getInformerJwt(state: any) {
    return state.informerJwt;
  },
  getInformerDefaultAssistant(state: any) {
    return state.informerDefaultAssistant;
  },
};

export const mutations: MutationTree<AiState> = {
  SET_INFORMER_JWT(state: any, informerJwt: string) {
    state.informerJwt = informerJwt;
  },
  SET_INFORMER_DEFAULT_ASSISTANT(state: any, informerDefaultAssistant: string) {
    state.informerDefaultAssistant = informerDefaultAssistant;
  },
};

export const actions: ActionTree<AiState, RootState> = {
  fetchInformerAI({ commit }, user) {
    return new Promise((resolve, reject) => {
      informerService
        .fetchInformerAI(user)
        .then((response) => {
          commit("SET_INFORMER_JWT", response.informerJwt);
          commit(
            "SET_INFORMER_DEFAULT_ASSISTANT",
            response.informerDefaultAssistant,
          );
          resolve(response);
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
};

export const ai: Module<AiState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
