import store from "../store";
import ClientAPI from "./ClientAPI";
import ApiService from "./ApiService";
export default class InformerService extends ApiService {
  constructor() {
    super();
  }

  async getReportUrl(client: any) {
    return ClientAPI.Get(`dashboard`, { client: client }).catch((error) => {
      store.dispatch("notification/add", {
        message: `Failed To Get Report URL: ${
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.error ||
          error?.message ||
          ""
        }`,
        type: "error",
      });
    });
  }

  async fetchInformerAI(user: any): Promise<any> {
    return ClientAPI.Post("GetInformerAI", user);
  }
}
