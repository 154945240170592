import ApiService from "./ApiService";

export default class LoginService extends ApiService {
  constructor() {
    super();
  }

  async fetchLoginData() {
    const response = await this.Post("GetUserLoginDetails", {});
    return response;
  }
}
