import Receipts from "@/types/receipts";
import ApiService from "./ApiService";
import ClientAPI from "./ClientAPI";

export interface ReceiptsPostRequest {
  record: Receipts;
  oldRecord?: Receipts;
  metaData: string;
}

export interface ReceiptsMetaData {
  po_number: string;
}

export interface ReceiptsRequest {
  id: string;
}

export default class ReceiptsService extends ApiService {
  constructor() {
    super("receipts", [{ name: "receipts", keyPath: "receipts_id" }]);
  }

  async fetchReceipts() {
    return ClientAPI.Get(`receipts`, {});
  }

  async searchReceipts(request: Partial<ReceiptsRequest>) {
    return ClientAPI.Post(`receipts/search`, request);
  }

  async createReceipts(request: Partial<ReceiptsPostRequest>) {
    return ClientAPI.Post(`receipts`, request);
  }

  async updateReceiptsById(id: string, params: Partial<ReceiptsPostRequest>) {
    return ClientAPI.Post(`receipts/${id}`, params);
  }

  async createReceiptsFromPurchaseOrder(po_number: string) {
    return ClientAPI.Post(`receipts/po/${po_number}`, {});
  }
  async getReceiptPdf(receiptPdfRequest: {
    id: string, 
    reportName: string, 
    pageWidth?: string,
    email?: any
  }) {
    const { id, reportName, pageWidth, email } = receiptPdfRequest;
    let params: any = {
      reportName,
      pageWidth,
      id
    };
    if (email) {
      params = {
        id,
        reportName,
        pageWidth,
        email: email.To,
        subject: email.Subject,
        body: email.Body,
        from: email.From,
        cc: email.Cc,
        bcc: email.Bcc,
        html: email.Html,
      };
    }
    return ClientAPI.Get(`receipts/${id}/pdf`, params);
  }
}
