import AbstractAccessGetter, { AccessRights } from "../AbstractAccessGetter";

export default class PosAccessGetter extends AbstractAccessGetter {
  constructor() {
    super();
  }

  getAccess(path: string, commands: string[]): boolean {
    return this.hasCommand(this.getWebCommand(path), commands);
  }

  getWebCommand(path: string): string {
    const pathList = path.split("/");
    let webName = "PSO.E";
    if (pathList[2] == "invoices") {
      webName = "AR.E";
    } else if (pathList[2] == "receipt-history") {
      webName = "CASH.E";
    } else if (pathList[2] == "pof") webName = "CASH.E";
    else if (pathList[2] == "payment") {
      webName = "CASH.E";
    }
    return webName;
  }
}
